<template>
    <v-card>
        <v-card-title>
            <div class="card-header text-unset">{{ tableTitle }}</div>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table dense :headers="header" :items="packages" item-key="id">
            <template v-slot:items="props">
                <td v-if="props.item.user" :style="getBGColor(props.item.packageName)">
                    {{ props.item.user.username }}
                    <span class="font-weight-bold float-right" v-if="props.item.user.viewerLeg === 1">L</span>
                    <span class="font-weight-bold float-right" v-else-if="props.item.user.viewerLeg === 2">R</span>
                </td>
                <td :style="getBGColor(props.item.packageName)">
                    <div>{{ props.item.id | formatID }}</div>
                </td>
                <td class="date-time-width" :style="getBGColor(props.item.packageName)">
                    <div>{{ props.item.id | formatDate }}</div>
                    <div v-for="upgrade in props.item.upgradedAt" :key="upgrade">
                        {{ upgrade | formatDate }} <font-awesome-icon :icon="['fas', 'arrow-up']" class="fsize-05" />
                    </div>
                </td>

                <td class="date-time-width" :style="getBGColor(props.item.packageName)">{{ props.item.expiresAt | formatDate
                }}</td>
                <td class="" :style="getBGColor(props.item.packageName)">
                    <button class="mb-2 mr-2 opacity-10 btn badge-btn" :class="'btn-' + props.item.color" disabled>
                        {{ props.item.packageName }}
                        <span v-if="props.item.noCommission === 0"
                            class="badge badge-warning badge-dot badge-dot-lg badge-dot-text">NC</span>
                    </button>
                </td>
                <td class="" v-if="props.item.button" :style="getBGColor(props.item.packageName)">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left pr-2">
                                    <div class="widget-numbers fsize-1" :class="'text-' + props.item.color">{{
                                        props.item.diff }}%</div>
                                </div>
                                <div class="widget-content-right w-100">
                                    <div class="progress-bar-xs progress">
                                        <div class="progress-bar" :class="'bg-' + props.item.color" role="progressbar"
                                            :aria-valuenow="props.item.diff" aria-valuemin="0" aria-valuemax="100"
                                            :style="'width: ' + props.item.diff + '%;'">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="value-min-width" v-if="props.item.freshValueInTokens"
                    :style="getBGColor(props.item.packageName)">
                    <span>{{ props.item.freshValueInTokens[0] | formatHtl }}</span> HTL
                </td>
                <td class="value-min-width" :style="getBGColor(props.item.packageName)">
                    <span>{{ props.item.totalValueInTokens | formatHtl }}</span> HTL
                </td>
                <td class="text-center" :style="getBGColor(props.item.packageName)">

                    <div role="group" class="btn-group-sm btn-group"
                        v-if="props.item.button && props.item.allowedUpgrades && props.item.allowedUpgrades.length > 0">
                        <upgrade-package-modal v-bind:currentType.sync="props.item"
                            v-bind:packageNames.sync="props.item.allowedUpgrades" :locked-rate="lockedRate" />
                    </div>
                </td>
                <td class="text-center" :style="getBGColor(props.item.packageName)">
                    <div role="group" class="btn-group-sm btn-group" v-if="props.item.button">
                        <secure-sell-package-modal v-bind:currentType.sync="props.item"
                            v-bind:packageName.sync="props.item.packageName"
                            v-bind:totalValueInTokens.sync="props.item.totalValueInTokens" :globalStats="globalStats"
                            v-bind:packageId.sync="props.item.id" v-bind:sellBackStatus.sync="props.item.sellBackStatus" />
                    </div>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook } from '@fortawesome/free-solid-svg-icons'
import UpgradePackageModal from "@/components/modals/UpgradePackageModal";
import SecureSellPackageModal from "@/components/modals/SecureSellPackageModal";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook);
export default {
    name: "PackagesTable",
    components: {
        UpgradePackageModal,
        SecureSellPackageModal,
        "font-awesome-icon": FontAwesomeIcon,
    },
    data() {
        return {
            search: "",
            headers: [
                {
                    value: "username",
                    text: this.$i18n.t("username"),
                    sortable: true,
                },
                {
                    value: "leg",
                    text: this.$i18n.t("leg"),
                    sortable: true,
                },
                { value: "packageId", text: "ID" },
                { value: "packageId", text: this.$i18n.t("date"), sortable: true, class: "text-center" },
                { value: "expires", text: this.$i18n.t("expires"), sortable: true },
                { value: "type", text: this.$i18n.t("type"), sortable: true },
                { value: "fresh", text: this.$i18n.t("fresh"), sortable: true },
                { value: "value", text: this.$i18n.t("value"), sortable: true },
            ],
            vouhers: null
        };
    },
    props: [
        "packages",
        "tableTitle",
        "header",
        "lockedRate",
        "globalStats"
    ],
    beforeMount() {
    },
    methods: {
        getBGColor(packageName) {
            if (packageName === 'Freedom Program Package') {
                return 'background-color:#98FF98;';//mint green   
            }
            return '';

        },
        getPackageName(type) {
            return (type < 0 || type > 6) ? this.$i18n.t("unknown") : this.packageNames[type];
        },
        getPackageColor(type) {
            switch (type) {
                case 0:
                    return "primary";
                case 1:
                    return "primary";
                case 2:
                    return "primary";
                case 3:
                    return "primary";
                case 4:
                    return "success";
                case 5:
                    return "success";
                case 6:
                    return "success";
                default:
                    return "danger";
            }
        },
        onUpdate() {
            this.$emit('onUpdate')
        }
    }
}
</script>
<style scoped>
.badge-dot-text {
    text-indent: 0em !important;
    width: 16px !important;
    height: 16px !important;
    color: white !important;
    border-radius: 50px !important;
    font-size: 0.5rem;
    padding: 2px 0 2px 0;
}

.badge-btn {
    min-width: 110px;
    border-radius: 50px !important;
}

.date-time-width {
    min-width: 150px;
}

.value-min-width {
    min-width: 150px;
}
</style>