<template>
    <div class="text-center">
        <div style="
                    height: 100%;
                    line-height: 100%;
                    text-align: center;
                    padding: 0 0;" v-if="pkg.sellBackStatus === 1">

            <span style="">Sell Back in progress</span>

        </div>
        <div v-else-if="pkg.sellBackStatus === 9">
            <table>
                <tr>
                    <td style="padding-left: 0px;padding-right: 0px;">
                        <v-btn disabled small class="ma-0 text-unset" style="margin: 5px;">
                            {{ $t('sell-title') }}
                        </v-btn>
                    </td>
                    <td style="padding-left: 5px;padding-right: 0px;">
                        <v-btn disabled small class="ma-0 text-unset" style="margin: 5px;">
                            Send to Shares B (+10%)
                        </v-btn>
                    </td>
                </tr>
            </table>
        </div>

        <div v-else-if="isAllowedToSellBack(pkg) === false">
            <table>
                <tr>
                    <td style="padding-left: 0px;padding-right: 0px;">
                        <v-btn disabled small class="ma-0 text-unset" style="margin: 5px;">
                            {{ $t('sell-title') }}
                        </v-btn>
                    </td>
                    <td style="padding-left: 5px;padding-right: 0px;">
                        <v-btn color="grey darken-3" dark @click="dialog = true" small class="text-unset ma-0">
                            Send to Shares B (+10%)
                        </v-btn>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else-if="isAllowedToSellBack(pkg) === true">

            <table>
                <tr>
                    <td style="padding-left: 0px;padding-right: 0px;">
                        <v-btn color="grey darken-3" dark @click="sell" small class="text-unset ma-0">
                            {{ $t('sell-title') }}
                        </v-btn>
                    </td>
                    <td style="padding-left: 5px;padding-right: 0px;">
                        <v-btn color="grey darken-3" dark @click="dialog = true" small class="text-unset ma-0">
                            Send to Shares B (+10%)
                        </v-btn>
                    </td>
                </tr>
            </table>
        </div>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card elevation="20">
                <v-card-title class="headline grey lighten-2" primary-title>Send to Shares B (+10%)</v-card-title>
                <v-card-title class="headline">
                    <template>
                        Your HTL Shares A from package will be converted into HTL Shares B. Are You sure?
                    </template>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <template>
                        <v-btn class="text-unset" small light flat @click="dialog = false">Back</v-btn>
                        <v-btn color="primary" class="text-unset" small dark @click="toB">Yes</v-btn>
                    </template>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";

export default {
    components: {
    },
    props: [
        'pkg'
    ],
    data() {
        console.log(this.pkg);

        return {
            dialog: false,
            selected: 0
        }
    },
    methods: {
        isAllowedToSellBack(pkg) {
            console.log('isAllowedToSellBack', pkg);
            var now = new Date();
            var sellAtDate = new Date(pkg.sellAt);
            return ((now > sellAtDate) === true && pkg.sellBackStatus === 0);
        },
        sell() {

            this.$apollo.mutate({
                mutation: gql`mutation($id:DateTime!,$rate:Float!){requestSellback(id:$id, rate:$rate)}`,
                variables: { id: this.pkg.id, rate: this.eurUsd }
            }).then(() => this.$router.go(0))
        },
        toB() {
            this.$router.go(0);
            this.$apollo.mutate({
                mutation: gql`mutation($id:DateTime!){toSharesB(id:$id)}`,
                variables: { id: this.pkg.id }
            }).then(() => this.$router.go(0))
        }
    },
    created() {
        // this.selected = this.packageNames[0];
        this.selected = "placeholder";
    },
    computed: {
        ...mapGetters({
            available: 'user/available',
            rate: 'prices/htlEur',
            rateStr: 'prices/rate',
            eurUsd: 'prices/eurUsd'
        })
    }
}
</script>

