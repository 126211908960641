<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="grey darken-3"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="text-unset px-3"
                >
                    {{$t('swap-title')}}
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{$t('swap-title')}}
                </v-card-title>

                <v-card-text>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="danger"
                        text
                        @click="dialog = false"
                        small
                        class="text-unset"
                    >
                        {{$t('close')}}
                    </v-btn>
                    <v-btn
                           color="success"
                           text
                           class="text-unset"
                           small
                           @click="swap"
                    >
                        {{$t('confirm-title')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import gql from "graphql-tag";

    export default {
        components: {
        },
        props: [
        'pkg'
        ],
        data () {
            console.log(this.pkg);

            return {
                dialog: false,
                selected: 0
            }
        },
        methods: {
            swap() {
                this.$apollo.mutate({
                    mutation: gql`mutation($type:ID!,$id:DateTime!){swapPackage(type:$type,id:$id)}`,
                    variables: {
                        id: this.pkg.id,
                        type: this.pkg.packageType,
                    }
                }).then(() => this.$router.go(0))
            }
        },
        created() {
            this.selected = "placeholder";
        },
        computed: {
            ...mapGetters({
                available: 'user/available',
                rate: 'prices/htlEur',
                rateStr: 'prices/rate'
            })
        }
    }
</script>

