<template>
  <div>

    <v-card>
      <v-card-title>
        <div class="card-header text-unset " style="width:100%">
          <h5><b>Freedom Program Deposit History</b></h5>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <div style="width: 100%;padding-left: 20px;">
        <input type="checkbox" value="Show unactive" v-model="showUnactive">
        <span style="padding-left: 5px; font-size: small; font-weight: bold;">Show unactive</span>
      </div>
      <div style="background-color: gainsboro;margin-top: 10px; padding-left: 20px;font-size: medium;">Deposits from
        ACTIVE freedom programs</div>
      <v-data-table class="mb-3" dense :headers="headers" :items="modifyDepositsOutput()" item-key="id"
        hide-default-footer="true" disable-pagination="true">

        <template v-slot:items="props">
          <td class="">
            <div v-if="props.item.rewardInstallment > 0">
              <font-awesome-icon style="color:orange;" v-if="props.item.rewardInstallment > 0" :icon="['fas', 'star']" />
              <span style="color:orange;" v-if="props.item.rewardInstallment > 0">Bonus</span>
            </div>
            {{ props.item.programName }}
          </td>
          <td class="date-time-width">
            {{ props.item.id | formatDate }}
          </td>

          <td class="">

            {{ props.item.valueInTokens | formatHtl }} HTL
          </td>
          <td>
            {{ props.item.valueInFiat | formatEur }} EUR
          </td>




        </template>
      </v-data-table>
      <div v-if="this.showUnactive === true"
        style="background-color: gainsboro;margin-top: 10px; padding-left: 20px;font-size: medium;">Deposits from UNACTIVE
        freedom programs</div>
      <v-data-table v-if="this.showUnactive === true" class="mb-3" dense :headers="headers"
        :items="modifeOldDepositsOutput()" item-key="id" hide-default-footer="true" disable-pagination="true">
        <template v-slot:items="props">
          <td class="">
            <div v-if="props.item.rewardInstallment > 0">
              <font-awesome-icon style="color:orange;" v-if="props.item.rewardInstallment > 0" :icon="['fas', 'star']" />
              <span style="color:orange;" v-if="props.item.rewardInstallment > 0">Bonus</span>
            </div>
            {{ props.item.programName }}
          </td>
          <td class="date-time-width">
            {{ props.item.id | formatDate }}
          </td>
          <td class="">
            {{ props.item.valueInTokens | formatHtl }} HTL
          </td>
          <td>
            {{ props.item.valueInFiat | formatEur }} EUR
          </td>
        </template>
      </v-data-table>
    </v-card>

  </div>
</template>
  
<script>

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar } from '@fortawesome/free-solid-svg-icons'

library.add(faStar);
export default {
  name: "MyFreedomProgramHistory",
  components: {
    FontAwesomeIcon

  },
  data() {
    return {

      headers: [
        {
          value: "programName",
          text: "Freedom Program",
          sortable: false,
        },
        { value: 'id', text: "Payment date", sortable: false },

        {
          value: "valueInTokens",
          text: "Value in HTL",
          sortable: true,
        },
        {
          value: "valueInFiat",
          text: "Value in EUR",
          sortable: true,
        },

      ],
      showUnactive: false

    };
  },
  props: [
    "freedomPrograms",
    "oldFreedomPrograms"
  ],
  mounted() {

  },




  methods: {

    modifyDepositsOutput() {

      var deposits = [];
      this.freedomPrograms.forEach((fp, index) => {

        fp.deposits.forEach(dep => {
          deposits.push({

            programName: "Freedom Program " + (1 + index),
            id: dep.id,
            valueInTokens: dep.valueInTokens,
            valueInFiat: dep.valueInFiat,
            rewardInstallment: dep.rewardInstallment
          });
        });

      });

      return deposits.sort((a, b) => (a.id > b.id ? 1 : -1));
    }
    ,
    modifeOldDepositsOutput() {
      var deposits = [];
      this.oldFreedomPrograms.forEach((fp, index) => {

        fp.deposits.forEach(dep => {
          deposits.push({

            programName: "Freedom Program " + (1 + index),
            id: dep.id,
            valueInTokens: dep.valueInTokens,
            valueInFiat: dep.valueInFiat,
            rewardInstallment: dep.rewardInstallment
          });
        });

      });

      return deposits.sort((a, b) => (a.id > b.id ? 1 : -1));
    }

  },
};
</script>
  
<style scoped></style>