<template>
    <v-bottom-sheet v-model="sheet" >
        <template v-slot:activator>
            <v-btn elevation="24"  color="primary" class="text-unset" small>{{$t('secure-price')}}</v-btn>
        </template>
        <v-list>
            <v-subheader>
                {{$t('upgrade-note', [root.packageName, pack.name])}}
                <span class="text-warning"> {{$t('fee-not-included')}}</span>
            </v-subheader>
            <v-list-tile v-for="currency in currencies" :key="currency._id" v-on:click="buy(currency)" :class="currency.title.toLowerCase()">
                <v-list-tile-avatar>
                    <v-avatar size="32px" tile>
                        <img :src="cryptoImg(currency.title)" :alt="currency.title" />
                    </v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-content class="price fsize-1">
                    <div class="w-100"><div class="w-50"><div class="right mr-1">{{$t('package-price')}}:</div></div><span class="w-50 ml-1">{{getPrice(currency._id).total.toFixed(currency.decimals)}} {{ currency.title }}</span></div>
                    <div class="w-100"><div class="w-50"><div class="right mr-1">{{$t('deposit-need')}}:</div></div><span class="w-50 ml-1">{{getPrice(currency._id).diff.toFixed(currency.decimals)}} {{ currency.title }}</span></div>
                </v-list-tile-content>
            </v-list-tile>
        </v-list>
    </v-bottom-sheet>
</template>
<script>
import {mapGetters} from 'vuex';
import gql from "graphql-tag";
export default {
    name: "UpgradeLock",
    data: () => ({
        sheet: false,
        tiles: null,
    }),
    props: [
        "price",
        "pack",
        "depositAddress",
        "root"
    ],
    created() {
        this.tiles = [
            {
                _id: 0,
                img: 'HTL.png',
                title: 'HTL',
                decimals: 2
            },
            {
                _id: 1,
                img: 'BTC-logo.png',
                title: 'BTC',
                decimals: 9
            },
            {
                _id: 2,
                img: 'USDT.png',
                title: 'USDT',
                decimals: 2
            },
            {
                _id: 3,
                img: 'LTC.png',
                title: 'LTC',
                decimals: 2
            }
        ]
    },
    computed: {
        ...mapGetters({
            htlEur: 'prices/htlEur',
            htlBtc: 'prices/htlBtc',
            htlUsd: 'prices/htlUsd',
            htlLtc: 'prices/htlLtc',
            token: 'prices/rate',
            btcRate: 'prices/btcRate',
            usdRate: 'prices/usdRate',
            available: 'user/available'
        }),
        currencies() {
            if (this.depositAddress) {
                return this.tiles.filter(p => p.title === this.depositAddress.currency);
            }
            return this.tiles;
        }
    },
    methods: {
        buy(currency) {
            if (this.depositAddress) {
                this.$apollo.mutate({
                    mutation: gql`mutation($token:String!,$currency:String!,$pack:ID!,$htlRate:Float!,$upgrade:ID!,$price:Int!)
{lockExchangeRate(token:$token,currency:$currency,pack:$pack,htlRate:$htlRate,upgrade:$upgrade,price:$price)}`,
                    variables: {
                        pack: this.pack.id,
                        token: this.token,
                        currency: currency.title,
                        htlRate: this.getRate(currency._id),
                        upgrade: this.root.id,
                        price: this.pack.price / 10000
                    },
                }).then(() => {
                    this.sheet = false
                    this.$router.push("/buy-packages")
                })
            }
            else {
                this.$apollo.mutate({
                    mutation: gql`mutation($token:String!,$currency:String!,$htlRate:Float!,$upgrade:ID!,$price:Int!,$pack:ID!)
{lockExchangeRateAndGetAddress(token:$token,currency:$currency,pack:$pack,htlRate:$htlRate,upgrade:$upgrade,price:$price)}`,
                    variables: {
                        pack: this.pack.id,
                        token: this.token,
                        currency: currency.title,
                        upgrade: this.root.id,
                        price: this.pack.price / 10000,
                        htlRate: this.getRate(currency._id)
                    },
                }).then(() => {
                    this.sheet = false
                    this.$router.push("/buy-packages")
                })
            }
        },
        getRate: function(currency) {
            if (this.depositAddress) return this.depositAddress.lockedRate;
            switch (currency) {
                case 0:
                    return 1;
                case 1:
                    return this.htlBtc;
                case 2:
                    return this.htlUsd;
                case 3:
                    return this.htlLtc
            }
            return 1;
        },
        getPrice: function(currency) {
            if (this.depositAddress) return this.getLockedPrice();
            switch (currency) {
                case 0:
                    return this.getHtlPrice();
                case 1:
                    return this.getBtcPrice();
                case 2:
                    return this.getUsdPrice();
                case 3:
                    return this.getLtcPrice();
            }
            return this.getHtlPrice();
        },
        getLockedPrice() {
            let total = this.pack.price * this.depositAddress.lockedRate / this.htlEur / 10000;
            let available = this.available * this.depositAddress.lockedRate / 1e8;
            return {
                total: total,
                available: available,
                diff: total - available
            }
        },
        getHtlPrice() {
            let total = this.pack.price / this.htlEur / 10000;
            return {
                total: total,
                available: this.available / 1e8,
                diff: total - this.available / 1e8
            }
        },
        getBtcPrice() {
            let total = this.pack.price * this.htlBtc / this.htlEur / 10000;
            let availableBtc = this.available * this.htlBtc / 1e8;
            return {
                total: total,
                available: availableBtc,
                diff: total - availableBtc
            }
        },
        getUsdPrice() {
            let total = this.pack.price * this.htlUsd / this.htlEur / 10000;
            let availableBtc = this.available * this.htlUsd / 1e8;
            return {
                total: total,
                available: availableBtc,
                diff: total - availableBtc
            }
        },
        getLtcPrice() {
            let total = this.pack.price * this.htlLtc / this.htlEur / 10000;
            let availableBtc = this.available * this.htlLtc / 1e8;
            return {
                total: total,
                available: availableBtc,
                diff: total - availableBtc
            }
        },
        isMobile() {
            return screen.width <= 760;
        },
        cryptoImg(currency) {
            switch (currency) {
                case "HTL":
                    return '/images/crypto/HTL.png'
                case "BTC":
                    return '/images/crypto/BTC-logo.png'
                case "USDT":
                    return '/images/crypto/USDT.png'
                case "LTC":
                    return '/images/crypto/LTC.png'
            }
        }
    }
}
</script>
<style scoped>
.v-dialog__container {
    width: 100%;
}
.htl {
    background-color: rgba(86, 170, 255, 0.2);
}
.btc {
    background-color: rgba(255, 112, 0, 0.2);
}
.usdt {
    background-color: rgba(186, 255, 175, 0.2);
}
.price {
    justify-content: center;
    align-items: center !important;
}
.price > div > span {
    font-weight: 700;
}
.price > div {
    display: flex;
}
@media (max-width: 420px) {
    .price {
        font-size: 12px;
    }
    .price > p > span {
        display: block;
    }
}
</style>