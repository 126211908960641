<template>
    <v-card>
        <v-card-title>
            <div class="card-header text-unset">{{tableTitle}}</div>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table dense :headers="header" :items="packages" item-key="id">
            <template v-slot:items="props">
                <td class="date-time-width">{{ props.item.expiresAt|formatDate }}</td>
                <td class="date-time-width">{{ props.item.dateSellEligible|formatDate }}</td>
                <td class="">
                    <button class="mb-2 mr-2 opacity-10 btn badge-btn" :class="'btn-' + props.item.color" disabled>
                        {{ props.item.packageName}}
                        <span v-if="props.item.noCommission === 0" class="badge badge-warning badge-dot badge-dot-lg badge-dot-text">NC</span>
                    </button>
                    <button v-if="props.item.sold" class="mb-2 mr-2 opacity-10 btn badge-btn" :class="'btn-danger'" disabled>
                        {{$t('package-sold-title')}}
                    </button>
                </td>
                <td class="">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left pr-2">
                                    <div class="widget-numbers fsize-1" :class="'text-' + props.item.color">{{ props.item.diff }}%</div>
                                </div>
                                <div class="widget-content-right w-100">
                                    <div class="progress-bar-xs progress">
                                        <div class="progress-bar" :class="'bg-' + props.item.color" role="progressbar" :aria-valuenow="props.item.diff"
                                             aria-valuemin="0" aria-valuemax="100" :style="'width: ' + props.item.diff + '%;'">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="value-min-width">
                    <span>{{ props.item.totalValueInTokens|formatHtl }}/{{ props.item.paidValueInFiat|formatEur }}</span> HTL A
                </td>
                <td class="value-min-width">
                    <span>{{ props.item.sharesAFromLoyalty != null ? props.item.sharesAFromLoyalty : 0 | formatHtl }}</span> HTL A
                </td>

                <td class="date-time-width">
                    <span v-if="props.item.swappedToSharesB">{{  }}</span>
                </td>
                <td class="value-min-width">
                    <span v-if="props.item.swappedToSharesB">{{  }}</span>
                </td>
                
                <td class="date-time-width">
                    <span v-if="props.item.sold">{{ }}</span>
                </td>
                <td class="value-min-width">
                    <span v-if="props.item.sold">{{  }}</span>
                </td>


                <td class="text-end">
                    <div role="group" class="btn-group-sm btn-group">
                        <sell-package-modal :pkg="props.item" v-if="props.item.buttonSell"/>
                        <swap-package-modal :pkg="props.item" v-if="props.item.buttonSwap"/>
                    </div>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook } from '@fortawesome/free-solid-svg-icons'
import SwapPackageModal from "@/components/modals/SwapPackageModal";
import SellPackageModal from "@/components/modals/SellPackageModal";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
library.add(faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook);
export default {
    name: "SwapTable",
    components: {
        SellPackageModal,
        SwapPackageModal,
        // "font-awesome-icon": FontAwesomeIcon,
    },
    data() {
        return {
            search: "",
            // headers: [
            //     {
            //         value: "username",
            //         text: this.$i18n.t("username"),
            //         sortable: true,
            //     },
            //     {
            //         value: "leg",
            //         text: this.$i18n.t("leg"),
            //         sortable: true,
            //     },
            //     {value: "packageId", text: this.$i18n.t("date"), sortable: true, class: "text-center"},
            //     {value: "expires", text: this.$i18n.t("expires"), sortable: true},
            //     {value: "type", text: this.$i18n.t("type"), sortable: true},
            //     {value: "fresh", text: this.$i18n.t("fresh"), sortable: true},
            //     {value: "value", text: this.$i18n.t("value"), sortable: true},
            //     // {value: "valueFromPackage", text: this.$i18n.t("value"), sortable: false},
            //     {value: "sharesAFromLoyalty", text: this.$i18n.t("value"), sortable: false},
            // ],
            vouhers: null
        };
    },
    props: [
        "packages",
        "tableTitle",
        "header",
        "lockedRate"
    ],
    beforeMount() {
    },
    methods: {
        getPackageName(type) {
            return (type < 0 || type > 6) ? this.$i18n.t("unknown") : this.packageNames[type];
        },
        getPackageColor(type) {
            switch (type) {
                case 0:
                    return "primary";
                case 1:
                    return "primary";
                case 2:
                    return "primary";
                case 3:
                    return "primary";
                case 4:
                    return "success";
                case 5:
                    return "success";
                case 6:
                    return "success";
                default:
                    return "danger";
            }
        },
        onUpdate() {
            this.$emit('onUpdate')
        }
    }
}
</script>
<style scoped>
.badge-dot-text {
    text-indent: 0em !important;
    width: 16px!important;
    height: 16px!important;
    color: white!important;
    border-radius: 50px!important;
    font-size: 0.5rem;
    padding: 2px 0 2px 0;
}
.badge-btn {
    min-width: 110px;
    border-radius: 50px!important;
}
.date-time-width {
    min-width: 150px;
}
.value-min-width {
    min-width: 150px;
}
</style>