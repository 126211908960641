<template>
  <div v-if="page">
    <h2>{{ $t("my-freedom-program") }}</h2>
    <div v-if="!!page.doc.content" class="row mb-3">
      <page-title :subheading="page.doc.content"></page-title>
    </div>


    <div v-if="this.activeFreedomPrograms.length === 0">
      <template>
        <div class="card-body">
          <v-card class="mx-auto" style="padding-bottom: 10px;" max-width="400">
            <v-card-text class="pb-0 card-title-htl fsize-2" style="text-align: center;">
              No Active Freedom Program.
            </v-card-text>
          </v-card>
        </div>
      </template>
    </div>

    <my-freedom-program-details :nextPaymentEnd="this.activeFreedomPrograms[0].nextPaymentEnd" :programNumber="1"
      :timeToPay="this.timeToPay[0]" :freedomPrograms="this.activeFreedomPrograms[0]" :programDeposit="50"
      :pack="this.activeFreedomPrograms[0].programName" :depositAddress="page.viewer.depositAddress"
      :freedomPercents="freedomPercents[0]" :available="page.viewer.totals.tokens" :tokenx="token"
      :lockedRate="page.lockedRate" :htlEur="htlEur" v-if="this.activeFreedomPrograms.length > 0">
    </my-freedom-program-details>

    <my-freedom-program-details :nextPaymentEnd="this.activeFreedomPrograms[1].nextPaymentEnd" :programNumber="2"
      :timeToPay="this.timeToPay[1]" :freedomPrograms="this.activeFreedomPrograms[1]" :programDeposit="50"
      :pack="this.activeFreedomPrograms[1].programName" :depositAddress="page.viewer.depositAddress"
      :freedomPercents="freedomPercents[1]" :available="page.viewer.totals.tokens" :tokenx="token"
      :lockedRate="page.lockedRate" :htlEur="htlEur" v-if="this.activeFreedomPrograms.length > 1">
    </my-freedom-program-details>

    <my-freedom-program-details :nextPaymentEnd="this.activeFreedomPrograms[2].nextPaymentEnd" :programNumber="3"
      :timeToPay="this.timeToPay[2]" :freedomPrograms="this.activeFreedomPrograms[2]" :programDeposit="50"
      :pack="this.activeFreedomPrograms[2].programName" :depositAddress="page.viewer.depositAddress"
      :freedomPercents="freedomPercents[2]" :available="page.viewer.totals.tokens" :tokenx="token"
      :lockedRate="page.lockedRate" :htlEur="htlEur" v-if="this.activeFreedomPrograms.length > 2">
    </my-freedom-program-details>
    <my-freedom-program-history :freedomPrograms="this.activeFreedomPrograms"
      :oldFreedomPrograms="this.oldFreedomPrograms"></my-freedom-program-history>








  </div>
</template>
  
<script>
import gql from "graphql-tag";
import PackagesTable from "@/components/Packages/PackagesTable";
import MyFreedomProgramDetails from "@/components/Packages/MyFreedomProgramDetails";
import MyFreedomProgramHistory from "@/components/Packages/MyFreedomProgramHistory";
import * as moment from "moment";
import PageTitle from "@/components/common/PageTitle";
import SwapTable from "@/components/Packages/SwapTable";
import { mapGetters } from "vuex";
export default {
  name: "MyFreedomProgram",
  components: {
    PageTitle,
    MyFreedomProgramDetails,
    MyFreedomProgramHistory
    // SwapTable,
  },
  data() {
    return {

      page: null,
      headers: [
        { value: 'id', text: "Payment date", sortable: false },
        { value: "nextPaymentStart", text: "Next Payment date", sortable: true },
        {
          value: "percentage",
          text: "Till next payment",
          sortable: false,
        },
        {
          value: "programName",
          text: "Freedom Program",
          sortable: false,
        },
        {
          value: "valueInTokens",
          text: "Value in HTL",
          sortable: true,
        },
        {
          value: "valueInFiat",
          text: "Value in EUR",
          sortable: true,
        },

        {
          value: "totalValueInTokens",
          text: "Total Value in HTL",
          sortable: true,
        },

        {
          value: "totalValueInFiat",
          text: "Total Value in EUR",
          sortable: true,
        },
      ],
      freedomPercents: [0, 0, 0],
      nextPaymentEnd: ['', '', ''],
      programEnd: [null, null, null],
      timeToPay: [null, null, null],
      dialog: false,
      dialog2Confirmation: false,
      newDeposit: 123,
      activeFreedomPrograms: [],
      oldFreedomPrograms: []



    };
  },
  mounted() {

  },



  apollo: {
    page: {
      query: gql`
          query page {
            doc(id: "my-freedom-program") {
              content
              title
            }
            viewer {
              id
              freedomPrograms {
                id
  	            active
	              nextPaymentStart
                nextPaymentEnd
                programEnd
	              programName
	              programFriendlyName
	              totalValueInFiat
	              totalValueInTokens
                programValueInFiat
                programValueInTokens
                deposits {
                  id
                  sequenceNumber
                  valueInFiat 
                  valueInTokens  
                  paymentDelay
                  rewardInstallment

                }
              }
              packages {
                id
                active
                expiresAt
                upgradedAt
                packageName
                totalValueInTokens
                valueInFiat
                noCommission
                allowedUpgrades {
                  id
                  name
                  price
                  expiryDays
                  hash
                }
              }

              totals {
                tokens
              }
              depositAddress {
                id
                address
                currency
                expiresAt
                lockedRate
              }
            }
            lockedRate {
              expiresAt
              rate
              rateCrypto
              token
              pack
              currency
              htlRate
              upgrade
              price
            }
            viewerIsAdmin
          }
        `,

      update: (data) => data,
      result({ data }) {
        if (data) {

          this.activeFreedomPrograms = [];
          this.oldFreedomPrograms = [];

          data.viewer.freedomPrograms.forEach((fp) => {
            if (fp.active === true) {
              this.activeFreedomPrograms.push(fp);
            }
            else {
              this.oldFreedomPrograms.push(fp);
            }
          });

          if (this.activeFreedomPrograms.length > 0) {
            this.freedomPercents[0] = this.getPercentage(+new Date(this.activeFreedomPrograms[0].nextPaymentStart));
            this.nextPaymentEnd[0] = +new Date(this.activeFreedomPrograms[0].nextPaymentEnd);
            this.programEnd[0] = +new Date(this.activeFreedomPrograms[0].programEnd);
            //če zamujamo s plačilom
            if (+new Date(this.activeFreedomPrograms[0].nextPaymentEnd) <= +new Date()) {
              this.timeToPay[0] = {
                weAreLate: true,
                time: this.getTimeDiff(new Date(), this.programEnd[0])
              };
            }
            //če je že čas za plačilo
            else if (+new Date(this.activeFreedomPrograms[0].nextPaymentStart) <= +new Date()) {
              this.timeToPay[0] = {
                weAreLate: false,
                time: this.getTimeDiff(new Date(), this.nextPaymentEnd[0])
              };
            }
            else {
              this.timeToPay[0] = null;
            }

          }
          if (this.activeFreedomPrograms.length > 1) {
            this.freedomPercents[1] = this.getPercentage(+new Date(this.activeFreedomPrograms[1].nextPaymentStart));
            this.nextPaymentEnd[1] = +new Date(this.activeFreedomPrograms[1].nextPaymentEnd);
            this.programEnd[1] = +new Date(this.activeFreedomPrograms[1].programEnd);

            //če zamujamo s plačilom
            if (+new Date(this.activeFreedomPrograms[1].nextPaymentEnd) <= +new Date()) {
              this.timeToPay[1] = {
                weAreLate: true,
                time: this.getTimeDiff(new Date(), this.programEnd[1])
              };
            }
            //če je že čas za plačilo
            else if (+new Date(this.activeFreedomPrograms[1].nextPaymentStart) <= +new Date()) {
              this.timeToPay[1] = {
                weAreLate: false,
                time: this.getTimeDiff(new Date(), this.nextPaymentEnd[1])
              };
            }
            else {
              this.timeToPay[1] = null;
            }

          }
          if (this.activeFreedomPrograms.length > 2) {
            this.freedomPercents[2] = this.getPercentage(+new Date(this.activeFreedomPrograms[2].nextPaymentStart));
            this.nextPaymentEnd[2] = +new Date(this.activeFreedomPrograms[2].nextPaymentEnd);
            this.programEnd[2] = +new Date(this.activeFreedomPrograms[2].programEnd);

            //če zamujamo s plačilom
            if (+new Date(this.activeFreedomPrograms[2].nextPaymentEnd) <= +new Date()) {
              this.timeToPay[2] = {
                weAreLate: true,
                time: this.getTimeDiff(new Date(), this.programEnd[2])
              };
            }
            //če je že čas za plačilo
            else if (+new Date(this.activeFreedomPrograms[2].nextPaymentStart) <= +new Date()) {
              this.timeToPay[2] = {
                weAreLate: false,
                time: this.getTimeDiff(new Date(), this.nextPaymentEnd[2])
              };
            }
            else {
              this.timeToPay[2] = null;
            }

          }

          this.$store.commit("user/updateAvailable", {
            tokens: data.viewer.totals.tokens,
          });
          if (data.viewerIsAdmin) {
            this.$store.commit("user/setAdmin");
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      htlEur: 'prices/htlEur',
      token: 'prices/rate'
    })
  },
  methods: {
    getPercentage(nextPaymentDate) {
      return Math.round(

        (30 //št. dni kakor je dolgo obdobje
          - ((new Date(nextPaymentDate) - new Date())
            /
            (1000 * 60 * 60 * 24))) * 3.333 //3.333 je en dan v procentih ali izracunano 1/30
      );
    },

    getTimeDiff(dateStart, dateEmd) {
      var delta = Math.abs(dateEmd - dateStart) / 1000;

      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      var seconds = Math.round(delta % 60);

      delta = seconds + 60 * minutes + 3600 * hours + 86400 * days;


      return { day: days, hour: hours, minute: minutes, second: seconds, delta: delta }

    },
    packToFriendlyName(e) {
      switch (e) {
        case '50-fp1':
        case '50-fp2':
          return 'Basic';
        case '100-fp1':
        case '100-fp2':
          return 'Advanced';
        case '200-fp1':
        case '200-fp2':
          return 'Premium';
      }
      return 'Basic';
    },



    transformDataActive(p) {
      var now = new Date();
      var fullDiff = moment(p.expiresAt).diff(moment(p.id));
      var diff = moment(now).diff(moment(p.id));
      return {
        diff: Math.round((100 * diff) / fullDiff),
        color:
          p.valueInFiat[0] < 19000000
            ? "primary"
            : p.valueInFiat[0] < 900000000
              ? "success"
              : "dark",
        button: true,
        allowedUpgrades: p.allowedUpgrades,
        ...p,
      };
    },
    transformDataSwap(p) {
      var result = {};
      var now = new Date();
      {
        let fullDiff = moment(p.dateSellEligible).diff(moment(p.id));
        let diff = moment(now).diff(moment(p.id));
        let percentFilled = Math.min(Math.round((100 * diff) / fullDiff), 100);

        Object.assign(p, {
          diff: percentFilled,
          color: "danger",
          buttonSell: diff >= fullDiff,
          buttonSwap: true,
          // allowedUpgradesSwap: p.allowedUpgrades
        });
      }

      return p;
    },
    transformDataExpired(p) {
      return {
        color:
          p.valueInFiat[0] < 19000000
            ? "primary"
            : p.valueInFiat[0] < 900000000
              ? "success"
              : "dark",
        ...p,
      };
    },
  },
};
</script>
  
<style scoped></style>