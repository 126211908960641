<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="sellBackStatus !== 9 && isAllowedToSellBack(currentType) === false" color="grey darken-3" dark
                    v-bind="attrs" v-on="on" small aria-label="Request for early termination of contract"
                    title="Request for early termination of contract" class="text-unset px-3">
                    Secure Sell
                </v-btn>
                <div v-else-if="sellBackStatus === 9 && isAllowedToSellBack(currentType) === false">Secure sell pending...
                </div>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Request for the calculation of the value at the security price
                </v-card-title>

                <v-card-text>
                    You're about to request for early termination of contract.
                    <br /> Current secured price for 1 HTL is {{ securedPrice.toFixed(2) }} EUR
                    ( {{ (securedPrice * eurUsd).toFixed(2) }} USDT )
                    <br />Package of {{ (currentType.totalValueInTokens / 10e7).toFixed(2) }} HTL would be transformed into
                    {{ ((currentType.totalValueInTokens / 10e7) * (securedPrice * eurUsd)).toFixed(2) }} USDT



                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="danger" text @click="dialog = false" small class="text-unset">
                        {{ $t('close') }}
                    </v-btn>
                    <v-btn color="success" text class="text-unset" small @click="dialog2Confirmation = true;">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialog2Confirmation" max-width="500px">
            <v-card elevation="20">
                <v-card-title class="headline grey lighten-2" primary-title>Confirm </v-card-title>
                <v-card-title class="headline">
                    <template>
                        Are you sure?
                        <br />
                        This action is ireversable!
                    </template>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-unset" small light flat @click="dialog2Confirmation = false">No</v-btn>
                    <v-btn color="primary" class="text-unset" small dark @click="secureSellRequest">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";

export default {
    components: {

    },
    props: [
        'currentType',
        'packageName',
        'totalValueInTokens',
        'globalStats',
        'packageId',
        'sellBackStatus'
    ],
    data() {

        return {
            dialog: false,
            dialog2Confirmation: false
        }
    },
    methods: {

        secureSellRequest() {
            this.dialog2Confirmation = false;
            this.dialog = false;
            this.$apollo.mutate({
                mutation: gql`mutation($id:DateTime!,$token:String!,$eurUsd:Float){requestSecureSell(id:$id,token:$token,eurUsd:$eurUsd)}`,
                variables: {
                    id: this.packageId,
                    token: this.rateStr,
                    eurUsd: this.eurUsd
                }
            }).then(() => this.$router.go(0))
        },
        isAllowedToSellBack(pkg) {
            var now = new Date();
            var sellAtDate = new Date(pkg.sellAt);
            return ((now > sellAtDate) === true && pkg.sellBackStatus === 0);
        }
    },

    computed: {
        securedPrice() {
            return (10e3 * this.globalStats.hotelBudget) / (this.globalStats.held + this.globalStats.shares + this.globalStats.sharesA + this.globalStats.packageActive)
        },

        ...mapGetters({
            available: 'user/available',
            rate: 'prices/htlEur',
            rateStr: 'prices/rate',
            eurUsd: 'prices/eurUsd'
        })
    }
}
</script>

