<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="currentType.sellBackStatus !== 9" color="grey darken-3" dark v-bind="attrs" v-on="on" small
                    class="text-unset px-3">
                    {{ $t('upgrade') }}
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ $t('upgrade-package') }}
                </v-card-title>

                <v-card-text>
                    <div class="input-group input-group-lg">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{ $t('upgrade-to') }}</span>
                        </div>
                        <select class="custom-select" v-model="selected">
                            <option v-for="(pkg, index) in packageNames" :key="index" v-bind:value="pkg">{{ pkg.name }}
                            </option>
                        </select>
                    </div>
                    <br />
                    <span v-html="$t('upgrade-question', [currentType.packageName, selected.name])"> </span>
                    <b v-if="selected.price"> {{ (selected.price / rate * 1e4) | formatHtl }} HTL</b>
                    <b v-else>0 HTL</b>?
                    <v-spacer />
                    <span class="text-warning" v-if="available < selected.price / rate * 1e4">
                        {{ $t('upgrade-deposit') }} {{ (selected.price / rate * 1e4 - available) | formatHtl }} HTL
                    </span>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="danger" text @click="dialog = false" small class="text-unset">
                        {{ $t('close') }}
                    </v-btn>
                    <v-btn color="success" text class="text-unset" small v-if="available >= selected.price / rate * 1e4"
                        @click="upgrade">
                        {{ $t('upgrade') }}
                    </v-btn>
                    <upgrade-lock v-if="available < selected.price / rate * 1e4" :root="currentType" :pack="selected"
                        :deposit-address="lockedRate" />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";
import UpgradeLock from "@/components/Packages/UpgradeLock";

export default {
    components: {
        UpgradeLock
    },
    props: [
        'currentType',
        'packageNames',
        "lockedRate"
    ],
    data() {
        return {
            dialog: false,
            selected: 0
        }
    },
    methods: {
        getUpgradeList(currentType) {
            return this.packageNames.slice(currentType + 1, this.packageNames.length)
        },
        upgrade() {
            this.$apollo.mutate({
                mutation: gql`mutation($id:DateTime!,$type:ID!,$token:String!,$eurUsd:Float){upgradePackage(id:$id,type:$type,token:$token,eurUsd:$eurUsd)}`,
                variables: {
                    id: this.currentType.id,
                    type: this.selected.hash,
                    token: this.rateStr,
                    eurUsd: this.eurUsd
                }
            }).then(() => this.$router.go(0))
        }
    },
    created() {
        this.selected = this.packageNames[0];
    },
    computed: {
        ...mapGetters({
            available: 'user/available',
            rate: 'prices/htlEur',
            rateStr: 'prices/rate',
            eurUsd: 'prices/eurUsd'
        })
    }
}
</script>

